<template>
  <div>
    <list
      ref="refBrandsList"
      sort-by="equipoId"
      refetch-records-name="equipos"
      key-field="EquipoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fecthRecord"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'
import router from '@/router'

export default {
  components: {
    List,
  },
  setup() {
    const { fetchEquipmentsBrands } = tiService()
    const refBrandsList = ref(null)
    const filters = ref([])
    const fecthRecord = (data, callback) => {
      fetchEquipmentsBrands(router.currentRoute.params.tipoMarcaId, data, records => {
        callback(records)
      })
    }
    const tableColumns = [
      {
        key: 'equipoId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'TipoEquipoMarcaModeloId',
      },
      {
        key: 'tipoEquipoMarcaModelo.nombre',
        label: i18n.t('AppTI.model.name'),
        sortable: false,
      },
      {
        key: 'procesador',
        label: i18n.t('AppTI.equipment.cpu'),
        sortable: false,
      },
      {
        key: 'numeroSerie',
        label: i18n.t('AppTI.equipment.serialNumber'),
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-brands-details',
        params: ['tipoMarcaId'],
        icon: 'Edit2Icon',
      },
    ])
    const actions = ref([])

    return {
      refBrandsList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fecthRecord,
      fetchEquipmentsBrands,
    }
  },
}
</script>
