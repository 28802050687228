<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Routes.editBrand') }}</span>
          </template>
          <brand-edit-tab/>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Routes.equipments') }}</span>
          </template>
          <brand-equipment-list/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import tiService from '@/services/informationTecnology.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import BrandEditTab from './BrandEdit.vue'
import BrandEquipmentList from './BrandEquipmentList.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BrandEditTab,
    BrandEquipmentList,
  },
  setup() {
    const brandData = ref(null)
    const { fetchBrand } = tiService()
    fetchBrand(router.currentRoute.params.tipoMarcaId, data => {
      brandData.value = data
    })

    return {
      brandData,
    }
  },

}
</script>
